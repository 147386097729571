<div class="form-container">
  <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
    <div class="form-group">
      <label for="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        [(ngModel)]="model.email"
        required
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label for="password">Password:</label>
      <input
        type="password"
        id="password"
        name="password"
        [(ngModel)]="model.password"
        required
        class="form-control"
      />
    </div>

    <button type="submit" [disabled]="!loginForm.valid">Login</button>
  </form>
</div>
