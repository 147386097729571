import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-home',
  template: `
    <div>
      <button (click)="start()">Start</button>
    </div>
  `,
  styles: [``],
})
export class HomeComponent {
  constructor(private router: Router) {}

  start() {
    this.router.navigate(['pet']);
  }
}
