import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@kudasai-frontend/util';
import { Observable, tap } from 'rxjs';
import { Pet, PetBuilder } from './data.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private BASE_URL = '';

  constructor(
    @Inject(ENVIRONMENT) private environment: IEnvironment,
    private http: HttpClient
  ) {
    this.BASE_URL = environment.api;
  }

  healthCheck() {
    return this.http
      .get(`${this.environment.api}`, { responseType: 'text' })
      .pipe(
        tap((m) => {
          console.log(
            `Client: ${
              this.environment.production ? 'Production' : 'Development'
            }`
          );
          console.log(`API: ${m}`);
        })
      );
  }

  getMyPermissions = (): Observable<string[]> =>
    this.http.get<string[]>(`${this.BASE_URL}/permissions`);

  getMyPet = (): Observable<any> => this.http.get<Pet>(`${this.BASE_URL}/pet`);

  buildPet = (petBuilder: PetBuilder): Observable<any> =>
    this.http.post<Pet>(`${this.BASE_URL}/pet/build`, petBuilder);
}
