import { Injectable } from '@angular/core';
import { DataService } from '@kudasai-frontend/data';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private dataService: DataService) {}

  hasSomePermissions(permissions: string[]): Observable<boolean> {
    console.log(permissions);
    return this.dataService
      .getMyPermissions()
      .pipe(
        map((permissionList) =>
          permissionList.some((i) => permissions.find((p) => p == i))
        )
      );
  }
}
