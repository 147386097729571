import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DataService } from '@kudasai-frontend/data';

@Component({
  selector: 'app-pet-builder',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './pet-builder.component.html',
  styleUrl: './pet-builder.component.css',
})
export class PetBuilderComponent {
  form = new FormGroup({
    petId: new FormControl(null),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    what: new FormControl('', Validators.required),
    likes: new FormControl('', Validators.required),
    dislikes: new FormControl('', Validators.required),
    from: new FormControl('', Validators.required),
    favoriteColor: new FormControl('', Validators.required),
  });

  constructor(private dataService: DataService) {}

  save() {
    if (this.form.valid) {
      this.dataService.buildPet(this.form.getRawValue()).subscribe();
    } else {
      console.log('Form is not valid');
    }
  }
}
