import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, concatMap, filter, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PermissionsService } from '../services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
    private auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const requiredPlan = route.data['permissions'];

    return this.auth.user$.pipe(
      filter((user) => !!user),
      concatMap(() => {
        return this.permissionsService.hasSomePermissions(requiredPlan).pipe(
          map((res) => {
            if (res) {
              return true;
            } else {
              this.router.navigate(['pricing']);
              return false;
            }
          })
        );
      })
    );
  }
}
