import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'auth-sign-in',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css',
})
export class SignInComponent {
  model = {
    email: '',
    password: '',
  };

  constructor(private auth: AuthService) {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      console.log('Form Submitted!', this.model);
      this.auth.createUser(this.model.email, this.model.password).then(() => {
        alert('Ok');
      });
    }
  }
}
