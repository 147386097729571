import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authInterceptorFn: HttpInterceptorFn = (
  req: any,
  next: any
): Observable<any> => {
  const auth = inject(AuthService);
  return from(auth.getToken()).pipe(
    switchMap((token) => {
      if (token != null) {
        req = req.clone({
          setHeaders: { Authorization: 'Bearer ' + token },
        });
      }
      return next(req);
    })
  );
};
