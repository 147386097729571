import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  user,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ENVIRONMENT } from '@kudasai-frontend/util';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private readonly BASE_URL = inject(ENVIRONMENT).api;
  user$ = user(this.auth);
  userSubscription: Subscription;
  isLoggedIn$ = new BehaviorSubject(false);
  displayName$ = new BehaviorSubject<string | null>(null);
  email$ = new BehaviorSubject<string | null>(null);

  private actionCodeSettings = {
    url: window.location.origin + '/pet',
    handleCodeInApp: true,
  };

  constructor(private router: Router, private http: HttpClient) {
    this.userSubscription = this.user$.subscribe((aUser: any | null) => {
      console.debug(aUser);
      this.isLoggedIn$.next(aUser !== null);
      this.displayName$.next(aUser?.displayName ?? aUser?.email);
      this.email$.next(aUser?.email);
    });
  }

  getToken(): Promise<string | null> {
    return this.auth.authStateReady().then(() => {
      return this.auth.currentUser?.getIdToken() ?? Promise.resolve(null);
    });
  }

  signOut(): Promise<void> {
    return this.auth.signOut().then((result) => {
      window.localStorage.clear();
      this.router.navigate(['']);
    });
  }

  createUser(email: string, pw: string) {
    return createUserWithEmailAndPassword(this.auth, email, pw)
      .then((userCred) => {
        //
      })
      .catch((error) => {
        // handle error
        // errors https://stackoverflow.com/a/73585472
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(error);
        if (errorCode === 'auth/email-already-in-use') {
          alert('Email already in use!');
        } else if (errorCode === 'auth/weak-password') {
          alert('weak password');
        }
      });
  }

  signIn(email: string, pw: string) {
    return signInWithEmailAndPassword(this.auth, email, pw)
      .then(() => {
        //
      })
      .catch((error) => {
        // handle error
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(error);
        if (errorCode === 'wrong-password') {
          alert('Wrong password');
        }
      });
  }

  sendPasswordReset(email: string) {
    return sendPasswordResetEmail(this.auth, email, this.actionCodeSettings)
      .then(() => {
        //
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }

  private signInInternalUser(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/account/sign-in`, {});
  }
}
