import { Route } from '@angular/router';
import {
  AuthGuard,
  PermissionsGuard,
  SignInComponent,
} from '@kudasai-frontend/auth';
import { HomeComponent } from './components/home.component';
import { PetBuilderComponent } from './components/pet-builder/pet-builder.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'pet',
    canActivate: [AuthGuard, PermissionsGuard],
    data: { permissions: ['basic'] },
    component: PetBuilderComponent,
  },
];
