import { Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@kudasai-frontend/auth';
import { DataService } from '@kudasai-frontend/data';
import { ENVIRONMENT, IEnvironment } from '@kudasai-frontend/util';
import { WindowComponent } from './components/window/window.component';

@Component({
  standalone: true,
  imports: [RouterModule, WindowComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [],
})
export class AppComponent {
  constructor(
    @Inject(ENVIRONMENT) private e: IEnvironment,
    private dataService: DataService,
    private authService: AuthService
  ) {
    this.dataService.healthCheck().subscribe();
  }
  title = 'Kudasai';
}
