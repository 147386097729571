export const environment = {
  production: false,
  api: 'https://dev.api.kudasai.app',
  firebase: {
    apiKey: 'AIzaSyDC8GsXmKCyFyHrGEwFrsKlTS4hYGjlKr0',
    authDomain: 'development-kudasai.firebaseapp.com',
    projectId: 'development-kudasai',
    storageBucket: 'development-kudasai.appspot.com',
    messagingSenderId: '43078523875',
    appId: '1:43078523875:web:95ae550afc611757455ed6',
  },
  stripe: {},
};
