<div class="form-container">
  <form [formGroup]="form" (ngSubmit)="save()">
    <p>
      My pet
      <input
        type="text"
        formControlName="name"
        placeholder="name of your pet"
        required
      />
      is a
      <input
        type="text"
        formControlName="what"
        placeholder="type of animal"
        required
      />
      from
      <input
        type="text"
        formControlName="from"
        placeholder="place or origin"
        required
      />
      that likes
      <input
        type="text"
        formControlName="likes"
        placeholder="something pet likes"
        required
      />
      and dislikes
      <input
        type="text"
        formControlName="dislikes"
        placeholder="something pet dislikes"
        required
      />. Its favorite color is
      <input
        type="text"
        formControlName="favoriteColor"
        placeholder="favorite color"
        required
      />
      and best described as
      <textarea
        type="text"
        formControlName="description"
        placeholder="description of your pet"
        required
      ></textarea
      >.
    </p>
    <button type="submit">Submit</button>
  </form>
</div>
